/* global config, Promise, angular */

'use strict';

angular.module('managerApp').factory('dashboard', function (http, toast) {
    const url = config.apiURL + 'dashboard/';
    function errorHandler(err) {
        if (err) {
            if (err.message) {
                if (err.type !== 'HTTPService') {
                    return Promise.reject(err.message);
                } else {
                    toast.show(err.message, 'error', true);
                }
            } else {
                if (err.data && err.data.message) {
                    return Promise.reject(err.data);
                }
            }
            return Promise.reject(err);
        }
        return Promise.reject();
    }
    function prepareFilters(filters) {
        let f = '';
        for (let i in filters) {
            f += (f === '' ? '?' : '&') + 'filter[' + i + ']=' + filters[i];
        }
        return f;
    }

    return {
        getTaxes: (filters) => {
            let f = prepareFilters(filters);
            let _url = url + 'taxes' + f;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        getRefunds: (filters) => {
            let f = prepareFilters(filters);
            let _url = url + 'refunds' + f;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        getProceduresNbByTerminal: (data) => {
            let _url = url + 'procedures-terminals';
            return http.post(_url, data).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        getClientsPhonesCompletedProcedures: (data) => {
            let _url = url + 'procedures-phones';
            return http.post(_url, data).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        getClientsContactCompletedProcedures: (data) => {
            let _url = url + 'procedures-contacts';
            return http.post(_url, data).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        getPaidProceduresCounts: (filters) => {
            let f = prepareFilters(filters);
            let _url = url + 'procedures/paid' + f;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        getProceduresTotalsAndCountsForAPeriod: (filters) => {
            let f = prepareFilters(filters);
            let _url = url + 'procedures/totals-counts' + f;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        getProceduresCountByOrigin: (filters) => {
            let f = prepareFilters(filters);
            let _url = url + 'procedures/origins' + f;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        getWeeklyStats: (filters) => {
            let f = prepareFilters(filters);
            let _url = url + 'procedures/weekly' + f;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        getEvolutionStats: (filters) => {
            let f = prepareFilters(filters);
            let _url = url + 'procedures/evolutions' + f;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        getUsersStatuses: (filters) => {
            let f = prepareFilters(filters);
            let _url = url + 'users/statuses' + f;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        getUsersStats: (filters) => {
            let f = prepareFilters(filters);
            let _url = url + 'users/stats' + f;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        getTurnover: (filters) => { /* chiffre d'affaires */
            let f = prepareFilters(filters);
            let _url = url + 'procedures/turnover' + f;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        getCountsBySourcePerMonthExcel: (filters) => {
            let f = prepareFilters(filters);
            let _url = url + 'procedures/source-month' + f;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        getCompletedCIMProceduresTaxes: (filters) => {
            let f = prepareFilters(filters);
            let _url = url + 'procedures/cim/taxes' + f;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        },
        getPayments: (filters) => {
            let f = prepareFilters(filters);
            let _url = url + 'procedures/payments' + f;
            return http.get(_url).then((res) => {
                return Promise.resolve(res.data);
            }).catch(errorHandler);
        }
    };
});